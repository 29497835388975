<template>
  <div>
    <div class="uk-section uk-section-xsmall uk-padding-remove-top">
      <div
        data-uk-sticky="offset:52;media: @m"
          class="uk-background-default breadcrumb"
          style="padding: 15px 35px;border-bottom: 1px solid rgb(229, 229, 229);"
      >
        <div class="uk-flex uk-flex-between uk-flex-middle">
          <div class="uk-flex uk-flex-middle">
            <h1 class="uk-h5 uk-margin-remove uk-flex uk-flex-middle" style="color: rgb(51, 51, 51)">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor" class="uk-margin-small-right">
              <path fill-rule="evenodd" d="M7 2a1 1 0 00-.707 1.707L7 4.414v3.758a1 1 0 01-.293.707l-4 4C.817 14.769 2.156 18 4.828 18h10.343c2.673 0 4.012-3.231 2.122-5.121l-4-4A1 1 0 0113 8.172V4.414l.707-.707A1 1 0 0013 2H7zm2 6.172V4h2v4.172a3 3 0 00.879 2.12l1.027 1.028a4 4 0 00-2.171.102l-.47.156a4 4 0 01-2.53 0l-.563-.187a1.993 1.993 0 00-.114-.035l1.063-1.063A3 3 0 009 8.172z" clip-rule="evenodd" />
            </svg>
              Messtechniker
            </h1>
          </div>
          <div>
            <router-link :to="{ name: 'technician-edit' }" class="uk-button uk-button-primary uk-button-small uk-border-rounded">
              <span uk-icon="plus-circle"></span>
              Neuen Messtechniker anlegen
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-container uk-margin-top">
      <div class="uk-grid uk-grid-small" data-uk-grid>
        <div class="uk-width-1-1">
          <div class="uk-card uk-card-default uk-margin-large-bottom">
            <div class="uk-card-body">
              <div class="uk-grid uk-grid-small uk-flex uk-flex-middle">
                <div class="uk-width-auto">
                  <search v-slot="{ props, activateFilter }">
                    <div class="uk-width-medium">
                      <input 
                        type="text" 
                        v-model="props.query" 
                        placeholder="Firma, Name, ..." 
                        @keyup.enter.prevent="activateFilter"
                        class="uk-input uk-border-rounded">
                    </div>
                  </search>
                </div>
              </div>
              <table
                class="uk-table uk-table-hover uk-table-striped uk-table-responsive"
                v-if="technicians.length"
              >
                <thead>
                  <tr>
                    <th class="uk-table-expand">Name</th>
                    <th class="uk-table-expand">Anschrift</th>
                    <th>Telefon</th>
                    <th class="uk-table-shrink"></th>
                    <th style="width: 60px;"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="uk-visible-toggle"
                    v-for="technician in technicians"
                    :key="technician.id"
                  >
                    <td>
                      <router-link
                        :to="{
                          name: 'technician-edit',
                          params: { id: technician.id },
                        }"
                      >
                        <span v-if="technician.company">
                          {{ technician.company }}
                        </span>
                        <span v-else>
                          {{ technician.firstname }} {{ technician.lastname }}
                        </span>
                      </router-link>
                    </td>
                    <td>
                      {{ technician.street }}, {{ technician.zipcode }}
                      {{ technician.city }}
                    </td>
                    <td>
                      {{ technician.phone }}
                    </td>
                    <td>
                      {{
                          new Date(technician.createdAt)
                          .toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' })
                      }}
                    </td>
                    <td class="uk-text-right">
                      <router-link
                        class="uk-icon-link uk-margin-small-right uk-text-primary"
                        data-uk-icon="pencil"
                        :to="{
                          name: 'technician-edit',
                          params: { id: technician.id },
                        }"
                      >
                      </router-link>
                      <a
                        class="uk-icon-link uk-text-danger"
                        data-uk-icon="trash"
                        href="#"
                        @click.prevent="remove(technician.id)"
                      >
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TechnicianService from '@/services/technician.service.js'
import 'izitoast/dist/css/iziToast.min.css'
import iziToast from 'izitoast/dist/js/iziToast.min.js'
import Search from '@/components/Search'
export default {
  data() {
    return {
      technicians: [],
      checked: [],
      allSelected: false,
      meta: null,
    }
  },
  components: {
    Search
  },
  mounted() {
    this.getTechnicians()
  },
  watch: {
    "$route.query": {
      handler() {
        this.getTechnicians();
      },
      deep: true
    }
  },
  computed: {},
  methods: {
    getTechnicians() {
      TechnicianService.getList({ ...this.$route.query}).then((response) => {
        this.technicians = response.data.data
        this.meta = response.data.meta
      })
    },
    remove(id) {
      const $this = this
      this.$uikit.modal
        .confirm('Messtechniker löschen?', {
          labels: {
            cancel: 'Abbrechen',
            ok: 'Löschen',
          },
        })
        .then(
          function() {
            TechnicianService.delete(id).then(() => {
              iziToast.success({
                title: 'OK',
                message: 'Messtechniker gelöscht!',
                timeout: 3000,
                position: 'bottomRight',
                transitionIn: 'fadeInLeft',
              })
              $this.getTechnicians()
            }).catch(() => {
              iziToast.error({
                title: 'Fehler',
                message: 'Messtechniker wird in einem Projekt verwendet!',
                timeout: 3000,
                position: 'bottomRight',
                transitionIn: 'fadeInLeft',
              })
            })
          },
          function() {
            console.log('Rejected.')
          }
        )
    },
  },
}
</script>
